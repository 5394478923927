@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,600,700,800,900&display=swap');
@import './Style/Variables.css';

body {
  margin: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px; /* Default text size */
  color: var(--textColor); /* Default text color */
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300; /* Typically, headings are bold or semi-bold */
  line-height: 1.6;
}

h1 {
  font-weight: 200;
  font-size: 24px;
}

h2 {
  font-weight: 300;
  font-size: 18px;
}

.bold {
  font-weight: 700;
}

.pageOverlay {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 999;
}

.aboutUsBgStyle {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-position: center top; */
  max-height: 800px;
}

.clickArea,
.toggleButton,
.tooltipButton,
.buttonStyle,
.creditChallengeContainer,
.catTileContainer,
.productTileContainer,
.menuItem,
.dropdownItem,
.addressTile,
.paymentMethodTile,
[role="button"],
a[role="button"] {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s forwards; /* Adjust the duration as needed */
}

.fadeInLong {
  opacity: 0;
  animation: fadeIn 0.5s forwards; /* Adjust the duration as needed */
  animation-delay: 1s; /* Delay before the animation starts */
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.only500 {
  display: none;
}

.not500 {
  display: flex;
}

.emptyWarning {
  border: 1px solid red !important;
  background-color: #ffe8ea;
}

.cookieBarContainer {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: calc(var(--headerHeight)*2);
  background-color: var(--mainColor);
  align-items: center;
  justify-content: center;
  color: var(--mainContent);
  font-size: 12px;
  z-index: 20;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  will-change: transform;
  a {
    color: #ffffff74;
  }
  a:hover {
    color: white;
  }
}

.hiddenCookieBar {
  transform: translateY(100%);
}

.visibleCookieBar {
  transform: translateY(0);
}

.cookieBarRow {
  display: flex;
  flex-direction: row;
  padding: 6px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.cookieBarColumn {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px 10px;
  gap: 16px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
}

.defaultPopupBg {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 25;
  background-color: rgba(0, 0, 0, 0.85);
}

.defaultPopupContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px;
  padding-top: 30px;
  border-radius: var(--mediumBorderRadius);
  margin-inline: 10px;
  gap: 6px;
  h1 {
    font-weight: 600;
  }
}

.defaultPopupCloseButton {
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 12px;
}

input {
  width: 100%;           /* Adjust width as needed, or to full width of the container */
  padding-left: 10px;          /* Padding for better text visibility and larger click area */
  margin: 0;         /* Space between inputs */
  box-sizing: border-box; /* Ensures padding does not affect the width */
  border: none; /* Subtle border */
  display: block;        /* Makes input take the full width of the container */
  height: var(--defaultElementHeight);
  border-radius: var(--tinyBorderRadius) !important;
  font-size: 15px;
  border: 1px solid var(--veryLightGray);
  color: var(--textColor);
}

textarea {
  width: 100%;           /* Adjust width as needed, or to full width of the container */
  padding-left: 10px;          /* Padding for better text visibility and larger click area */
  margin: 0;         /* Space between inputs */
  box-sizing: border-box; /* Ensures padding does not affect the width */
  border: none; /* Subtle border */
  display: block;        /* Makes input take the full width of the container */
  border-radius: var(--tinyBorderRadius) !important;
  font-size: 14px !important;
  border: 1px solid var(--veryLightGray);
  color: var(--textColor);
  font-family: inherit;
}

input:hover {
  background-color: var(--veryLightGray);
}

input:focus {
  background-color: var(--veryLightGray);
  outline: none;
  border: 1px solid var(--mainColor);
}

input::placeholder {
  /* font-style: italic; */
  /* opacity: 0.7; */
  color: var(--inputPlaceholderColor);
}

.mandatoryInput::after {
  content: '*';
  color: red !important;
}

select {
  display: flex;
  align-items: center; /* Ensure items are vertically centered */
  font-size: var(--defaultTextSize);
  color: var(--textColor);
  width: 100%;
  padding: 8px 10px; /* Adjust padding to avoid cropping */
  border: 1px solid var(--veryLightGray);
  border-radius: var(--smallBorderRadius);
  cursor: pointer;
  height: 36px;
  line-height: 20px; /* Ensure line-height matches the text size and padding */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.itemSelect {
  border: 1px solid var(--lightGray) !important;
  border-radius: var(--smallBorderRadius) !important;
}

.itemSelect:hover {
  /* border: 1px solid var(--secondColor) !important; */
  border-radius: var(--smallBorderRadius) !important;
  background-color: var(--veryFadedMainColor);
  .buttonColorSelectSeller {
      color: var(--mainContent);
      background-color: var(--mainColorHover);
      transition: background-color 0.2s;
  }
}

.selectedItem {
  border: 1px solid var(--mainColor) !important;
  border-radius: var(--smallBorderRadius) !important;
  background-color: var(--mainColor);
  color: white !important;
  transition: background-color 0.2s !important;
  /* border: 1px solid var(--mainColor) !important;
  border-radius: var(--smallBorderRadius) !important;
  background-color: var(--lightGray); */
}

.selectedItem:hover {
  background-color: var(--mainColorLighter) !important;
}

/* .selectedItem:hover {
  border-radius: var(--smallBorderRadius) !important;
  .buttonColorSelectSeller {
      color: var(--mainContent);
      background-color: var(--mainColorHover);
      transition: background-color 0.2s;
  }
} */

.noRightBorders {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.noLeftBorders {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

p {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 1rem; /* Default size for paragraph */
  line-height: 1.6; /* Good for readability */
}

strong {
  font-weight: bold;
}

a {
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  gap: 4px;
  text-decoration: none;
  /* color: var(--textColor); */
  color: var(--secondColor);
  transition: color 0.2s;
}

a:hover {
  text-decoration: none;
  color: var(--secondColor);
}

.colorTextLink {
  color: var(--secondColor);
  /* color: var(--mainColor); */
}

mark {
  background-color: var(--secondColor);
  /* background-color: var(--secondColor); */
  color: var(--secondContent);
  border-radius: var(--smallBorderRadius);
  padding: 0 6px;
  font-weight: 600;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButtonContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.error {
  color: red;
}

.main {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1; /* Takes up any available space */
  flex-direction: column;
  margin-top: var(--headerHeight);
  background-color: var(--veryLightGray);
  /* z-index: 0; */
  /* background-color: lightcoral; */
  overflow-x: hidden;
}

.mainHeaderOffset {
  margin-top: 0;
}

.dataViewHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  /* margin-bottom: 10px; */
  gap: 4px;
  .dataViewIcon {
    color: #bdbdbd;
  }
}

.noteRow {
  background-color: #fff5ad;
  padding-inline: 4px;
  border-radius: var(--smallBorderRadius);
  color: var(--textColor);
  width: fit-content;
}

.defaultDialog {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultDialogContainer {
  padding: 20px;
  border-radius: var(--smallBorderRadius);
  max-width: var(--defaultDialogWidth);
  max-height: var(--defaultDialogHeight);
  background-color: white;
}

.gridHeight {
  height: 90vh;
}

.productGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 10px;
  align-content: start;
  justify-content: center;
  background-color: lightcoral;

  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Optional: hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  .productTileContainer {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
}

.centerGrid {
  display: flex;
  align-items: center;
  width: fit-content;
}

.tileGrid {
  display: flex;
  flex-wrap: wrap;
  /* background-color: lightcoral; */
  align-items: start;
  justify-content: start;
  margin: 0 auto;
}

.tileGridHome {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  margin: 0 auto;
  gap: 16px;
  /* max-height: 400px;
  overflow-y: auto; */
}

.navMenuContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  gap: 16px;
  border-radius: var(--mediumBorderRadius);;
  background-color: white;
  min-width: 200px;
  .column {
    gap: 10px;
  }
  .row {
    gap: 6px;
  }
}

.catVehicleImg {
  display: flex;
  width: 100px;
}

.cardOpImgContainer {
  padding: 4px;
  border-radius: var(--smallBorderRadius);
  background-color: white;
}

.creditChallengeContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: start;
  padding-top: 80px;
  background-color: white;
  z-index: 999 !important;
}

.filterIndicator {
  position: absolute;
  top: 4px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondColor);
  border-radius: 50%;
  color: white;
  width: 12px;
  height: 12px;
  font-size: 10px;
}

@media (max-width: 1280px) {
  .tileGridHome {
    margin-bottom: 20px;
  }
}

@media (max-height: 930px) {
  .gridHeight {
    max-height: 830px;
  }
  .navMenuContainer {
    margin-bottom: 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: var(--smallBorderRadius);
  background-color: white;
  box-shadow: var(--defaultCardShadow);
}

.mainLogoSize {
  width: 160px;
}

.formContainer {
  width: 400px;
}

.formFieldRow {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.visibilityTogglePosition {
  position: absolute;
  right: 0;
  padding: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.productsPageBg {
  position: absolute;
  display: flex;
  width: 100%;
  height: 400px;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: start;
  background-color: #1B1B1B;
}

.UhdTopMargin {
  z-index: 1;
  margin-top: 330px !important;
}

.umuaramaHDnav {
  display: flex;
  transform: translateY(calc(var(--headerHeight) + 40px));
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin-inline: auto;
  color: white;
  gap: 6px;
  a {
    color: white;
  }
}

.umuaramaHDnav {
  a:hover {
    text-decoration: underline;
  }
}

.pageContent {
  width: 1280px;
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: var(--defaultMarginBottom);

  /* background-color: lightgoldenrodyellow; */
}

.legalStyle {
  p {
    line-height: 1.5 !important;
  }
  li {
    line-height: 2 !important;
  }
}

.pageContentLargeTopMargin {
  margin-top: 100px;
}
.pageHeader {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-items: start;
  column-gap: 20px;
  row-gap: 10px;
  margin-inline: 10px;
}

.pageContentSmall {
  width: 800px;
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: var(--defaultMarginBottom);

  /* background-color: lightgoldenrodyellow; */
}

.pageContentLarge {
  width: 1540px;
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: var(--defaultMarginBottom);
  /* background-color: lightgoldenrodyellow; */
}

.rowSeparator {
  border-bottom: 1px solid var(--veryLightGray);
}

.rowToColumn {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.rowToColumn800 {
  display: flex;
  flex-direction: row;
}

.rowToColumn960 {
  display: flex;
  flex-direction: row;
}

.rowToColumn500 {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.circleContainerGreen {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--greenText);
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 48px !important;
}

.circleContainerBlue {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blueText);
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 48px !important;
}

.circleContainerMain {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondColor);
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 48px !important;
}

.whatsappCtaContainerProductPage {
  display: flex;
  /* padding: 10px; */
  /* padding-inline: 10px; */
  /* color: var(--textColor); */
  align-items: end;
  gap: 10px;
  background-color: var(--veryLightGray);
  border-radius: var(--smallBorderRadius);
  padding-right: 10px;
  .mobileSpan {
    display: none;
    max-width: 100px;
    margin-left: 6px;
  }
  .deskSpan {
    display: flex;
    white-space: nowrap;
  }
}

.whatsappOption {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  color: var(--textColor);
  img {
    width: 42px;
    height: 42px;
    margin-right: 6px;
  }
}

/* 
.GoParcaQuote {
  display: flex;
  padding: 10px;
  border-radius: var(--smallBorderRadius);
  font-style: italic;
  background-color: var(--lightGray);
  align-items: center;
  justify-content: center;
  align-self: center;
} */

.GoParcaQuote {
  display: flex;
  padding: 10px;
  font-style: italic;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0 0 0;
  bottom: 0;
  background-color: var(--footerBg);
  /* background-color: var(--mainColor); */
  /* background-color: #282c34; */
  color: white;
  overflow-x: hidden;
  a {
    color: #ffffff74;
    display: flex;
  }
  .defaultItemBgDark {
    color: #ffffff74;
  }
  .footerLogo {
    width: 82px;
    fill: #ffffff74; /* Default fill color */
    transition: fill 0.3s; /* Smooth transition for hover effect */
  }
  .footerLogo:hover {
    fill: white; /* Change to desired hover color */
  }
  a:hover{
    color: white;
  }
  h4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.footerMenu {
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  width: 100%;
}

.leadFormContainer {
  display: flex;
  width: 100%;
  background-color: var(--mainColor);
  color: white;
  justify-content: center;
  padding: 20px 0;
}

.leadFormRow {
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  gap: 20px;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.leadFormText {
  white-space: nowrap;
}

.defaultItemBgDark {
  padding: 10px;
  border-radius: var(--smallBorderRadius);
  background-color: var(--footerDarker);
}

.defaultItemBgLight {
  padding: 10px;
  border-radius: var(--smallBorderRadius);
  background-color: var(--mainColorLighter);
}

.defaultBorderedItem {
  border-radius: var(--smallBorderRadius);
  border: 1px solid var(--lightGray);
  padding: 10px;
}

.defaultBorderedItemHighlight {
  border: 1px solid var(--mainColor) !important;
  border-radius: var(--mediumBorderRadius) !important;
  background-color: var(--veryFadedMainColor);
  padding: 10px;
}

.defaultBorderedItemHighlight:hover {
  border: 1px solid var(--mainColor);
  border-radius: var(--mediumBorderRadius);
  background-color: var(--veryFadedMainColor);
}

.defaultGrayBox {
  border-radius: var(--smallBorderRadius);
  padding: 10px;
  background-color: var(--lightGray);
  color: var(--textColor);
}

.userVehicleImg {
  width: 42px;
}

.hiddenLink {
  margin-left: 6px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.clickArea {
  cursor: pointer;
  border-radius: var(--smallBorderRadius);
  transition: background-color 0.2s ease;
}

.clickArea:hover {
  /* background-color: var(--veryLightGray); */
  background-color: var(--veryVeryLightGray);
  .hiddenLink {
    opacity: 1;
  }
}

.toggleButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 32px;
  padding: 4px;
  border-radius: 22px;
  border: 1px solid var(--lightGray)
}

.toggleButtonCircle {
  display: flex;
  width: 18px;
  height: 18px;
  background-color: rgb(220, 220, 220);
  border-radius: 50%;
  transform: translateX(-6px);
  transition: transform 0.1s ease-in-out;
}

.toggleButton:hover {
  .toggleButtonCircle {
    background-color: rgb(156, 156, 156);
  }
}

.toggleActive {
  .toggleButtonCircle {
    background-color: var(--secondColor);
    transform: translateX(6px);
  }
}

.toggleActive:hover {
  .toggleButtonCircle {
    background-color: var(--secondColorHover);
  }
}

.tooltipButton {
  position: relative;
  display: flex;
  cursor: help;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--lightGray);
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.tooltipContent {
  display: none;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid var(--lightGray);
  border-radius: var(--smallBorderRadius);
  width: 200px;
  a {
    color: var(--mainColor)
  }
  a:hover {
    color: var(--mainColorHover)
  }
}

.tooltipButton:hover {
  .tooltipContent {
    display: flex;
  }
}

.buttonStyle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: var(--smallBorderRadius);
  gap: 6px;
  transition: background-color 0.2s;
  a {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.disabledButton {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.buttonColorMain {
  background-color: var(--mainColor);
  color: var(--mainContent);
  transition: background-color 0.2s;
}
.buttonColorMain:hover {
  color: var(--mainContent);
  background-color: var(--mainColorHover);
}

.buttonColorSecond {
  background-color: var(--veryLightGray);
  color: var(--mainColor);
  transition: background-color 0.2s;
}
.buttonColorSecond:hover {
  color: var(--mainColor);
  background-color: var(--lightGray);
}

.buttonColorThird {
  background-color: var(--secondColor);
  color: var(--secondContent);
  fill: var(--secondContent);
  transition: background-color 0.2s;
}
.buttonColorThird:hover {
  background-color: var(--secondColorHover);
  color: var(--secondContent);
}

.buttonColorGreen {
  background-color: var(--highlightGreen);
  color: var(--textColor);
  transition: background-color 0.2s;
}
.buttonColorGreen:hover {
  color: var(--textColor);
  background-color: var(--highlightGreenHover);
}

.buttonColorLight {
  background-color: var(--veryLightGray);
  color: var(--textColor);
  transition: background-color 0.2s;
}
.buttonColorLight:hover {
  background-color: var(--lightGray);
}
.buttonColorWhite {
  background-color: white;
  color: var(--textColor);
  transition: background-color 0.2s;
}
.buttonColorWhite:hover {
  background-color: var(--veryLightGray);
}

.buttonColorTransparentDark {
  background-color: transparent;
  color: var(--textColor);
  transition: background-color 0.2s;
}
.buttonColorTransparentDark:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.buttonColorTransparentLight {
  background-color: transparent;
  color: white;
  transition: background-color 0.2s;
}
.buttonColorTransparentLight:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.disableButton {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

.overlayAlign {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayButtonLight {
  position: absolute;
  background-color: transparent;
  color: transparent;
  width: 100%;
  height: 100%;
}

.overlayButtonLight:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: var(--textColor);
}

.overlayButtonDark {
  position: absolute;
  background-color: transparent;
  color: transparent;
  width: 100%;
  height: 100%;
}

.overlayButtonDark:hover {
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--textColor);
}

.smallButtonCorner {
  position: absolute;
  cursor: pointer;
  top: -6px;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: background-color 0.2s;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.smallButtonCorner:hover {
  background-color: var(--mainColor);
  color: var(--mainContent)
}

.buttonLabelAnim {
  max-width: 60px;
  span {
    display: none;
  };
  transition: max-width 0.2s ease;
  overflow: hidden;
}
.buttonLabelAnim:hover {
  max-width: 300px;
  span {
    display: flex;
  };
}

.tinyButton {
  padding: 4px;
  border-radius: var(--smallBorderRadius);
  font-size: 14px !important;
}

.smallButton {
  padding: 10px;
  height: 36px;
  border-radius: var(--smallBorderRadius);
  font-size: 14px;
  white-space: nowrap;
}

.smallerButton {
  padding: 10px;
  height: 30px;
  border-radius: var(--smallBorderRadius);
  font-size: 14px;
  white-space: nowrap;
}

.largeButton {
  padding: 12px 16px;
  border-radius: var(--smallBorderRadius);
  font-size: 14px;
}

.giantButton {
  position: relative;
  padding: 12px 16px;
  font-size: 18px;
  background-image: var(--giantButtonGradient);
  transition: background-image 0.3s ease;
  overflow: hidden;
  color: var(--giantButtonGradientContent) !important;
  fill: var(--giantButtonGradientContent) !important;
}

.giantButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--giantButtonGradientHover);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 0;
}

.giantButton:hover::before {
  opacity: 1;
}

.giantButton span {
  position: relative;
  z-index: 1;
}

.giantButtonMain {
  position: relative;
  padding: 12px 16px;
  font-size: 18px;
  background-image: linear-gradient(to top right, #131b22, #1C252C);
  transition: background-image 0.3s ease;
  overflow: hidden;
  color: white !important;
}

.giantButtonMain::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top right, #0e141a, #131b22);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 0;
}

.giantButtonMain:hover::before {
  opacity: 1;
}

.giantButtonMain span {
  position: relative;
  z-index: 1;
}

.noCorners {
  border-radius: 0 !important;
}

.largeText {
  font-size: 18px;
  font-weight: 300;
}

.searchButtonSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 36px;
  flex-grow: 0;
  flex-shrink: 0;
}

.mainColorText {
  color: var(--mainColor);
}

.discountHighlight {
  cursor: default;
  padding: 2px 8px;
  border-radius: var(--smallBorderRadius);
  background-color: var(--highlightGreen);
  color: var(--darkTextColor);
  transition: background-color 0.2s;
  font-size: 20px;
  font-weight: 600 !important;
  white-space: nowrap;
  h2 {
    font-size: 20px;
    font-weight: 600 !important;
    white-space: nowrap;
  }
}

.discountHighlight:hover {
  background-color: var(--highlightGreenHover);
}

.textColor {
  color: var(--textColor) !important;
}

.redText {
  color: var(--redText) !important;
}

.greenText {
  color: var(--greenText) !important;
}

.grayedText {
  color: var(--grayedText);
}

.textNote {
  color: var(--grayedText);
  font-size: 12px;
  font-style: italic;
}

.originalPrice {
  text-decoration: line-through;
  color: var(--grayedText);
  line-height: 1.2;
}

.mainPrice {
  font-weight: 700;
  color: var(--mainColor);
  line-height: 1.4;
}

.productRowImg {
  width: 64px;
  height: 64px;
  border-radius: var(--smallBorderRadius);
  object-fit: contain;
  background-color: white;
}

.summaryProductRowImg {
  width: 36px;
  height: 36px;
  border-radius: var(--smallBorderRadius);
  object-fit: contain;
  background-color: white;
}

.expandible {
  display: flex; /* or display: block; */
  overflow: hidden;
  transition: max-height 0.2s ease, opacity 0.2s ease;
  max-height: 0;
  opacity: 0;
  visibility: hidden; /* Hides the element but allows transition */
}

.expanded {
  max-height: 500px; /* Set a value that is large enough for your content */
  opacity: 1;
  visibility: visible; /* Make the element visible */
  transition: max-height 0.2s ease, opacity 0.2s ease;
}

.hiddenUserData {
  max-height: 44px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.expandedUserData {
  max-height: 500px; /* Adjust this value based on the content */
  overflow-x: auto;
  transition: max-height 0.3s ease;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Spinner styles */
.loader {
  border: 5px solid var(--lightGray);
  border-top: 5px solid var(--mainColor); /* Change color to your preference */
  border-radius: 50%;
  width: 50px; /* Adjust size to your preference */
  height: 50px; /* Adjust size to your preference */
  animation: spin 1s linear infinite;
  margin: auto; /* Center the spinner */
}

/* Animation for the spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.noSelect {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
}

.highlight {
  border: 1px solid var(--secondColor) !important; /* Set the border color */
  position: relative; /* Required for positioning the shake animation */
  animation: shake 0.6s ease-out; /* Faster animation duration */
  animation-delay: 0.2s; /* Adjust the delay as needed */
  color: var(--secondColor) !important;
  .sellerHighlight {
      color: var(--secondColor) !important;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-4px);
  }
  25% {
    transform: translateX(4px);
  }
  37.5% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  62.5% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
  87.5% {
    transform: translateX(-4px);
  }
  100% {
    transform: translateX(0);
  }
}

#calculatedShipping {
  visibility: visible !important;
}

.legalTerm {
  h2 {
    color: var(--mainColor);
    font-weight: 600;
  }
  h3 {
    font-weight: 600;
  }
  a {
    display: inline;
    color: var(--secondColor);
  }
}

.floatingContainer {
  position: fixed;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 40px;
  right: 40px;
  z-index: 11;
}

.floatingButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transition: transform 0.2s ease;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.13);
}

.floatingButton:hover {
  transform: translateY(-4px);
}

.aboutUsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  h2 {
    font-size: 21px;
  }
  span {
    font-size: 14px;
  }
}

.GoParcaShowProduct {
  height: 80px;
}

.aboutUsEmpty {
  display: flex;
  flex: 1;
}

.aboutUsHomeContainer {
  border-radius: var(--smallBorderRadius);
  min-height: 400px;
  background-size: cover;      /* Ensures the background image covers the entire container */
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  background-position: center;  /* Centers the background image */
  background-image: url('./images/about_GoParts_bg_03.jpg');
}

.aboutUsMiddleMarginRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.reviewStarStaticMedium {
  color: var(--secondColor) !important;
  font-size: 28px !important;
}

.reviewStarStaticSmall {
  color: var(--secondColor) !important;
  font-size: 24px !important;
}

.defaultRowBrands {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 960px;
  /* gap: 10px; */
  .headerFilterOption {
    padding: 6px;
  }
  .headerFilterOption:hover {
    background-color: rgba(0, 0, 0, 0.1)
  }
  .headerFilterBrandLogo {
    width: 80px;
    height: 80px;
  }
}

.confirmCircle {
  font-size: 96px !important;
  color: var(--highlightGreen);
}

.purchaseConfirmContainer {
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin: 20px auto 40px auto;
  align-items: center;
  justify-content: center;
  gap: var(--mediumRowGap);
}

.purchaseSummaryContainer {
  display: flex;
  flex-direction: column;
  gap: var(--largeRowGap);
  /* width: 480px; */
  background-color: white;
  padding: var(--mediumPadding);
  border-radius: var(--smallBorderRadius);
  mark {
    background-color: var(--lightHighlightMark);
    /* background-color: var(--secondColor); */
    color: var(--textColor);
    border-radius: var(--smallBorderRadius);
    padding: 0 6px;
    font-weight: 600;
  }
}

.pixCodeContainer {
  display: flex;
  flex-direction: column;
  gap: var(--smallRowGap);
  padding: 10px;
  border-radius: var(--smallBorderRadius);
  border: 1px solid var(--lightGray);
}

.confirmationProductImg {
  height: 36px;
}

@media (min-width: 641px) {
  .column640 {
    align-items: start;
  }
}

@media (max-width: 1540px) {
  .pageContentLarge {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    margin-bottom: var(--defaultMarginBottom);
    box-shadow: none;
  }
}

@media (max-width: 1280px) {
  .pageContent {
    min-height: calc(100vh - var(--headerHeight)*2);
    width: 100%;
    margin: 0;
    margin-top: 20px;
    margin-bottom: var(--defaultMarginBottom);
    box-shadow: none;
  }
  .formContainer {
    margin: auto;
  }
  .rowToColumn {
    flex-direction: column !important;
  }
  .card {
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
  .aboutUsEmpty {
    display: none;
    flex: 0;
  }
  .leadFormContainer {
    margin-bottom: 20px;
  }
  .leadFormRow {
    flex-direction: column;
    padding-inline: 20px;
    gap: 10px;
  }
  .leadFormText {
    white-space: wrap;
  }
}

@media (max-width: 960px) {
  .rowToColumn960 {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .rowToColumn800 {
    flex-direction: column;
  }
  .googleContainer, .reclameContainer {
    min-height: auto;
    padding: 40px 20px;
  }
  .defaultDialogContainer {
    margin: 10px;
  }
  .footerMenu {
    flex-direction: column;
    .column {
      margin-left: 20px;
      margin-bottom: 40px;
    }
    h4 {
      margin-top: 20px;
      margin-bottom: 0;
    }
    h4:first-child {
      margin-top: 0
    }
  }
  .pageContentSmall {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    margin-bottom: var(--defaultMarginBottom);
    box-shadow: none;
  }
  .aboutUsHomeContainer {
    background-image: url('./images/about_GoParts_bg_04.jpg');
    min-height: 500px;
  }
  .aboutUsMiddleMarginRow {
    margin-top: 100px;
  }
  .reviewStarStaticSmall {
    color: var(--secondColor) !important;
    font-size: 18px !important;
  }
}

@media (max-width: 640px) {
  .productsPageBg {
    height: 240px;
  }
  .UhdTopMargin {
    margin-top: 180px !important;
  }
  .productGrid {
    .productTileContainer {
      margin-bottom: 20px;
    }
  }
  .whatsappCtaContainerProductPage {
    flex-direction: column;
    gap: 0;
  }
  .GoParcaQuote {
    font-size: 14px;
    width: calc(100% - 20px);
  }
  .pageContent, .pageContentLarge, .pageContentSmall {
    margin-top: 0;
  }
  .pageContentLargeTopMargin {
    margin-top: 90px;
  }
  .pageHeader {
    margin-top: 6px;
  }
  .column640 {
    align-items: normal;
    flex-direction: column;
  }
  .aboutUsHomeContainer {
    span {
      font-size: 14px;
    }
  }
  .confirmCircle {
    font-size: 64px !important;
  }
}

@media (max-width: 500px) {
  .only500 {
    display: flex;
  }
  .not500 {
    display: none;
  }
  .cookieBarContainer {
    min-height: calc(var(--headerHeightMobile)*2.5);
  }
  .hiddenCookieBar {
    transform: translateY(100%);
  }
  .cookieBarRow {
    font-size: 12px;
  }
  .aboutUsHomeContainer {
    /* min-height: 600px; */
    span {
      font-size: 13px;
    }
  }
  .aboutUsMiddleMarginRow {
    margin-top: 120px;
  }
  .hiddenOn500 {
    display: none !important;
  }
  .floatingContainer {
    bottom: 20px;
    right: 20px;
  }
  .floatingButton {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .main {
    margin-top: var(--headerHeightMobile);
  }
  .mainHeaderOffset {
    margin-top: 0;
  }
  h1 {
    font-size: 18px;
  }
  .formContainer {
    width: 100%;
    padding: 0;
  }
  .column500 {
    flex-direction: column;
  }
  .umuaramaHDnav {
    transform: translateY(calc(var(--headerHeightMobile) + 20px));
    margin-left: 20px;
  }
  .buttonLabelAnim:hover {
    max-width: none;
    span {
      display: none;
    }
  }
  .rowToColumn500 {
    flex-direction: column !important;
    align-items: start;
    gap: 10px;
  }
  .tileGrid {
    gap: 10px;
    padding-inline: 10px;
  }
  .tileGridHome {
    gap: 10px;
    /* margin-inline: 10px; */
    /* padding-inline: 10px; */
  }
  .tileClick {
    width: 100%;
  }
  .clickArea {
    .hiddenLink {
      opacity: 1;
    }
  }
  .defaultRowBrands {
    .headerFilterBrandLogo {
      width: 64px;
      height: 64px;
    }
  }
  .purchaseSummaryContainer {
    border-radius: 0;
  }
}