@import '../Style/Variables.css';

.productResponsive {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.productPageCard {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 660px;
    border-radius: var(--mediumBorderRadius);
    box-shadow: var(--defaultCardShadow);
}

.prodDetailsDesk {
    margin-top: 10px;
    width: 600px;
}

.prodDetailsMobile {
    display: none;
}

.compatsList {
    max-height: 240px;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    user-select: none; /* Disable text selection */
    background-color: white;
}

.compatsListImg {
    user-select: none; /* Disable text selection */
}

.productDescription {
    cursor: pointer;
    display: flex;
    padding: 6px;
    flex-direction: column;
    background-color: white;
    border-radius: var(--smallBorderRadius);
    border: 1px solid var(--lightGray);
}

.defaultImage {
    width: 600px;
    height: 600px;
    overflow: hidden; 
}

.productPageSwiper {
    width: 600px;
    height: 600px;
    overflow: hidden;
    .swiper-button-next, .swiper-button-prev {
        color: var(--mainColor);
        transition: color 0.2s;
    }
    
    .swiper-button-next:hover, .swiper-button-prev:hover {
        color: var(--mainColorHover);
    }
}

.productPageBg {
    position: absolute;
    display: flex;
    width: 100%;
    height: 540px;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: start;
}

.pageHighlight {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: center;
    color: white;
}

.pageHighlightImg {
    max-width: 800px;
    max-height: 90px;
    object-fit: cover;
}

.defaultBorderedItem {
    transition: border 0.2s ease;
}

.productSpecRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.specIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--lightGray);
    color: var(--grayedText);
    fill: var(--grayedText);
    flex-shrink: 0;
}

.sizePicker {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    padding: 0;
    background-color: white;
}

.selectedSizePicker {
    border: 1px solid var(--secondColor);
    color: var(--secondColor);
}

.colorPicker {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    padding: 0;
    background-color: white;
}

.colorPickerColor {
    display: flex;
    height: 18px;
    width: 18px;
    border-radius: 50%;
}

.selectedColorPicker {
    border: 1px solid var(--secondColor);
    color: var(--secondColor);
}

.outOfStock {
    cursor: not-allowed;
    position: relative;
    .colorPickerColor {
        opacity: 0.3;
    }
    span {
        opacity: 0.3;
    }
}

.outOfStock::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgb(165, 165, 165); /* You can change the color if needed */
    transform: rotate(-45deg);
    transform-origin: center;
}

.outOfStock:hover::before {
    content: 'indisponível';
    position: absolute;
    top: -20px; /* Adjust to position the text above the item */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75); /* Background for better readability */
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px; /* Adjust font size as needed */
    white-space: nowrap;
}

.productPageBrand {
    width: 42px;
    padding: 0;
}

.sellerBrand {
    width: 48px;
    height: 48px;
    padding: 0;
}

.sellerHighlight {
    color: var(--mainColor);
    transition: color 0.2s ease;
}

.charImg {
    display: flex;
    width: 120px;
    object-fit: contain;
}

.productInfoDiv {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: white;
    border-radius: var(--smallBorderRadius);
    gap: 10px;
}

.supportHighlight {
    justify-content: start;
    background-color: white;
    border-radius: var(--smallBorderRadius);
    border: 1px solid var(--lightGray);
    gap: 20px;
}

.productPageImg {
    cursor: pointer;
    display: flex;
    width: 600px;
    height: 600px;
    object-fit: contain;
    background-color: white;
    border-radius: var(--mediumBorderRadius);
}

.productPageSwiperThumbs {
    margin-top: 10px;
    width: 600px;
    height: 120px;
    border-radius: var(--mediumBorderRadius);
}

.productPageImgThumb {
    cursor: pointer;
    width: 120px;
    height: 120px;
    object-fit: contain;
    border-radius: var(--mediumBorderRadius);
    background-color: white;
}

.productPageSwiperZoom {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 10;
    .swiper-button-next, .swiper-button-prev {
        color: var(--mainColor);
        transition: color 0.2s, background-color 0.2s;
    }
    
    .swiper-button-next:hover, .swiper-button-prev:hover {
        color: var(--mainColorHover);
        background-color: var(--fadedMainColor);
    }
}

.productPageImgZoom {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
    border-radius: var(--largeBorderRadius);
}

.openZoom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeZoomButton {
    position: fixed;
    cursor: pointer;
    color: var(--mainColor);
    margin-top: var(--headerHeight);
    top: 10px;
    right: 10px;
}

.closeZoomButton:hover {
    color: var(--mainColorHover);
}

.productImgContainer {
    padding: 0;
    margin-bottom: -1px;
}

.sellersList {
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* background-color: white; */
    /* border-radius: var(--smallBorderRadius); */
    overflow: hidden;
}

.sellersShort {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: auto; /* Set the max height for the collapsed state */
    transition: max-height 0.8s ease-in;
}

.sellersLong {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 3000px; /* Set a large enough max-height for the expanded state */
    transition: max-height 0.8s ease-in;
}

@media (max-width: 1280px) {
    .productResponsive {
        justify-content: center;
    }

    .productHeader {
        width: 100%;
    }
    .productPageCard {
        width: 100%;
    }
    .prodDetails {
        width: 100%;
    }
    .defaultImage {
        width: 480px;
        height: 480px;
    }
    .productPageSwiper {
        width: 480px;
        height: 480px;
    }
    .productPageImg {
        width: 480px;
        height: 480px;
    }
    
    .productPageSwiperThumbs {
        width: 480px;
        height: 96px;
    }
    
    .productPageImgThumb {
        width: 96px;
        height: 96px;
    }
    .pageHighlight {
        flex-direction: column;
        height: 120px;
    }

    .pageHighlightImg {
        max-width: 100vw;
    }
    
    
}

@media (max-width: 960px) {
    .productResponsive {
        flex-direction: column;
        gap: 20px;
    }
    .productPageCard {
        border-radius: 0;
    }
    .prodDetailsDesk {
        display: none;
    }
    .prodDetailsMobile {
        display: flex;
        border-radius: 0;
    }
}

@media (max-width: 600px) {
    .productResponsive {
        gap: 1px;
        /* gap: 10px; */
    }
    .charImg {
        width: 100px;
    }
    .defaultImage {
        width: 100%;
        height: 100%;
    }
    .productPageSwiper {
        width: 100%;
        height: 100vw;
    }
    .productPageImg {
        width: 100%;
        height: 100vw;
        pointer-events: none;
        border-radius: 0;
    }
    .productPageSwiperThumbs {
        display: none;
        width: 100%;
        height: 20vw;
    }
    .productPageImgThumb {
        width: 20vw;
        height: 20vw;
    }
    .swiper-button-next, .swiper-button-prev {
        display: none !important;
    }
    /* .productImgContainer {
        padding: 10px 10px 0 10px;
    } */
}

@media (max-width: 500px) {
    .sellersShort {
        max-height: auto;
    }
}