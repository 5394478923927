@import '../Style/Variables.css';

.headerBackground {
  display: flex;
  width: 100vw;
  justify-content: center;
  position: fixed;
  top: 0;
  height: var(--headerHeight);
  /* background-color: white; */
  background-color: var(--headerBg);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.headerContainer {
  display: flex;
  width: 1280px;
  justify-content: center;
  align-items: center;
}

.headerLogo {
  display: flex;
  cursor: pointer;
  margin-inline: 10px;
}

.squareLogo {
  display: none;
  height: calc(var(--headerHeight) * 0.7);
  width: auto;
}

.mainLogo {
  height: calc(var(--headerHeight) * 0.7);
  width: auto;
  max-width: 160px;
  object-fit: contain;
}

.searchContainer {
  display: flex;
  align-items: center;
  position: relative !important;
  width: calc(100% - 20px);
  transition: width 0.3s ease-out;
  justify-content: end;
}

.focused {
  width: calc(100% - 20px);
}

.searchButtonHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: var(--headerHeightMobile);
  height: var(--defaultElementHeight);
  flex-grow: 0;
  flex-shrink: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.clearSearchButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: var(--defaultElementHeight);
  height: var(--defaultElementHeight);
  flex-grow: 0;
  flex-shrink: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.searchButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding-right: 4px;
}

.searchInput {
  height: 40px;
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  border-radius: var(--smallBorderRadius) !important;
  /* padding-right: var(--headerHeight); */
  /* height: calc(var(--headerHeight) * 0.7); */
}


.autoCompleteContainer {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--mainColor);
  color: white;
  top: var(--headerHeight);
  left: 0;
}

.headerFilterMainColumn {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin-inline: 10px;
}

.headerFilterRowBrands {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 840px;
  /* gap: 10px; */
}

.headerFilterRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  color: white;
  /* gap: 10px; */
}

.headerOptionsContainer {
  display: flex;
  justify-content: center;
  /* justify-content: start; */
  /* max-height: 70vh; */
  /* overflow-x: auto; */
  background-color: var(--mainColorDarker);
  border-radius: var(--mediumBorderRadius);
}

.headerFilterColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: white;
  gap: 10px;
  padding: 10px;
  /* font-size: 16px; */
  /* width: 800px; */
  /* overflow-y: auto; */
  /* background-color: rgba(255, 255, 255, 0.1); */
  /* border-radius: var(--mediumBorderRadius); */
}

.headerFilterTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 10px;
  border-radius: var(--smallBorderRadius);
  background-color: var(--mainColor);
  /* background-color: white; */
  color: white;
  /* color: var(--textColor); */
}

.headerFilterTileImg {
  width: 120px;
}

.headerFilterOption {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--defaultTextSize);
  padding: 10px;
  border-radius: var(--smallBorderRadius);
  font-size: 18px;
}

.headerFilterOption:hover {
  background-color: rgba(255, 255, 255, 0.1)
}

.headerFilterBrandLogo {
  width: 72px;
  height: 72px;
  border-radius: var(--smallBorderRadius);
}

.resultsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - var(--headerHeight));
  max-width: 1280px;
  overflow-y: auto;
}

/* For WebKit-based browsers */
.resultsMain::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* For Firefox */
.resultsMain {
  scrollbar-width: none; /* Hide scrollbar */
}

.results {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 40px;
}

.resultItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.resultTitle {
  font-size: 20px;
}

@media (max-width: 1280px) {
  .headerOptionsContainer {
    justify-content: center;
  }
  .headerContainer {
    width: 100%;
  }
  .results {
    margin-inline: 20px;
    justify-content: center;
    gap: 10px;
  }
  .headerFilterRow {
    max-height: 56vh;
    overflow-x: auto;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .mainLogo {
    display: none;
  }
  .squareLogo {
    display: flex;
    height: calc(var(--headerHeightMobile) * 0.7);
  }
}

@media (max-width: 500px) {
  .visible {
    display: flex;
  }
  .notVisible {
    display: none;
  }
  /* .focused {
    margin-right: 10px;
  } */
  .headerBackground {
    height: var(--headerHeightMobile);
  }
  .searchButtonHeader {
    width: var(--defaultElementHeight);
  }
  .searchInput {
    /* padding-right: calc(var(--headerHeightMobile) + 10px); */
  }
  .hideLabelMobile {
    span {
      display: none;
    }
  }
  .resultsMain {
    width: 100%;
    max-height: calc(100vh - var(--headerHeightMobile));
  }
  .results {
    gap: 20px;
  }
  .headerFilterColumn {
    width: calc(100vw - 40px);
  }
  .headerFilterBrandLogo {
    width: 64px;
    height: 64px;
  }
}

